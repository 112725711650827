function shouldUpdateCampaignDetails(newCampaignDetails, currentTimeInMs, campaignDetailsLifetimeInDays) {
  if (!localStorage.hasOwnProperty('campaign_details')) {
    // Always update if we have no existing campaign details.
    return true;
  }

  const campaignDetails = JSON.parse(localStorage.getItem('campaign_details'));
  const lastUpdateTimeInMs = parseInt(localStorage.getItem('campaign_details_updated_at'), 10);
  const timeSinceLastUpdateInDays = (currentTimeInMs - lastUpdateTimeInMs) / (24 * 60 * 60 * 1000);

  const isNewDirectVisit = !newCampaignDetails['referring_url'] && !newCampaignDetails['utm_campaign'];
  const isNewBrandVisit = newCampaignDetails['utm_campaign'] && newCampaignDetails['utm_campaign'].toLowerCase().includes('brand');
  const wasPreviousDirectVisit = !campaignDetails['referring_url'] && !campaignDetails['utm_campaign'];

  if (isNewDirectVisit || isNewBrandVisit) {
    // Allow update if campaign details have expired OR if the last visit was direct and the new one is a brand visit.
    return timeSinceLastUpdateInDays > campaignDetailsLifetimeInDays || (wasPreviousDirectVisit && isNewBrandVisit);
  }

  // For non-direct visits, update if the new campaign details are different.
  return localStorage.getItem('campaign_details') !== JSON.stringify(newCampaignDetails);
};

// Set campaign details
if (!sessionStorage.hasOwnProperty('campaign_details_set')) {
  const campaignDetailsLifetimeInDays = 180;
  const currentTimeInMs = Date.now();
  const urlParams = new URLSearchParams(document.location.search);

  const newCampaignDetails = {
    'landingPage': window.location.href || '',
    'referrer': document.referrer || '',
    'utmCampaign': urlParams.get('utm_campaign') || '',
    'utmContent': urlParams.get('utm_content') || '',
    'utmMedium': urlParams.get('utm_medium') || '',
    'utmSource': urlParams.get('utm_source') || '',
    'utmTerm': urlParams.get('utm_term') || '',
    'timestamp': new Date().toISOString(),
  };

  // Add to campaign_history
  const campaignHistory = JSON.parse(localStorage.getItem('campaign_history') || '[]');
  campaignHistory.push(newCampaignDetails);
  localStorage.setItem('campaign_history', JSON.stringify(campaignHistory));

  if (shouldUpdateCampaignDetails(newCampaignDetails, currentTimeInMs, campaignDetailsLifetimeInDays)) {
    localStorage.setItem('campaign_details', JSON.stringify(newCampaignDetails));
    localStorage.setItem('campaign_details_updated_at', currentTimeInMs);
  }

  if (!localStorage.hasOwnProperty('first_campaign_details')) {
    localStorage.setItem('first_campaign_details', localStorage.getItem('campaign_details'));
  }

  // Set a flag in the session storage so we can avoid calling this
  // routine more than once per session.
  sessionStorage.setItem('campaign_details_set', 'true');
}
